import { wrapWithProvider } from 'store/provider';
// import wrapPageElementWithTransition, {wrapRoot} from 'helpers/wrapPageElement';
// import LogRocket from 'logrocket';
import { inject } from '@vercel/analytics';

// eslint-disable-next-line react/prop-types
// export const wrapRootElement = wrapRootElement;
export const wrapRootElement = wrapWithProvider;

export const onClientEntry = () => {
  // LogRocket.init('q79eq6/visualma');
  inject();
}

// Page Transitions
// export const wrapPageElement = wrapPageElementWithTransition;




// const transitionDelay = 500

// export const shouldUpdateScroll = ({
//   routerProps: { location },
//   getSavedScrollPosition,
// }) => {
//   if (location.action === 'PUSH') {
//     window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
//   } else {
//     const savedPosition = getSavedScrollPosition(location)
//     window.setTimeout(
//       () => window.scrollTo(...(savedPosition || [0, 0])),
//       transitionDelay
//     )
//   }
//   return false
// }
