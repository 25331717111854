const kebabCase = (str) => {
  return str && str != '' && str.length > 1
    ? str
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .match(
          /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
        )
        .map((x) => x.toLowerCase())
        .join('-')
        .replace('3-d', '3d')
    : '';
};

const trackCustomEvent = (action, eventParameters) => {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('event', action, { ...eventParameters });
  }
};



const serviceUrls = {
  // kebabCase(category) --> service url
  'video-mapping-3d': '/video-mapping',
  'realidad-virtual': '/realidad-virtual',
  'realidad-aumentada': '/realidad-aumentada',
  museos: '/museos-instalaciones',
  arquitectura: '/arquitectura-recorrido-virtual',
  'animacion-y-modelado-3d': '/animacion-modelado-3d',
  'aplicaciones-y-videojuegos': '/aplicaciones-videojuegos',
  'escenarios-virtuales': '/set-virtual-produccion-cine-tv',
};

module.exports.serviceUrls = serviceUrls;
module.exports.kebabCase = kebabCase;
module.exports.trackCustomEvent = trackCustomEvent;
