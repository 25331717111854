import React, { useContext, useState } from 'react';
import { Alert, AlertIcon, Button, Box } from '@chakra-ui/react';
import { PhoneIcon } from '@chakra-ui/icons';
import { myContext } from '../store/provider';
import { trackCustomEvent } from '../helpers/functions';

const ContactBanner = ({ type = -1, lang = 'es' }) => {
  // eslint-disable-next-line no-unused-vars
  const { toggleDrawer, openDrawer } = useContext(myContext);
  const l = (...texts) => lang === 'en' ? texts[1] : texts[0]

  const openContacto = (btnType = -1) => {
    trackCustomEvent('open_contacto', {
      event_category: 'contacto',
      event_label: `ContactBanner ${btnType}`,
      value: 1,
      non_interaction: true,
    });

    openDrawer();
  };

  const items = [
    <Alert
      key="contactBanner0"
      id="contactBanner0"
      status="info"
      variant="solid"
      maxW="55em"
      maxH="64px"
      m="2em auto"
      height={['80px', 'auto']}
      lineHeight={1.2}
    >

      <AlertIcon height="20px" width="20px" boxSize="20px" display={['none', 'inline-block']} />

      <Box as="span" flexGrow="1" display={['inline-block']}>
        {l('Hagamos realidad tu proyecto', 'Let\'s make your project a reality')}
      </Box>
      <Button
        as="a"
        href="/contacto"
        onClick={(e) => {
          e.preventDefault();
          openContacto(0);
        }}
        size="sm"
        variant="outline"
        colorScheme="white"
        minWidth="140px"
        marginLeft={['16px', '32px']}
        backgroundColor="#fff"
        color="#3182ce"
        _hover={{ bg: '#1976d266', color: '#fff' }}
        paddingLeft="0"
        flexGrow="1"
      >
        <img
          src="/avatars/call-male.png"
          alt="avatar calling on the phone"
          loading="lazy"
          height="64" width="64"
          style={{ height: '64px' }}
        />
        {l('Llamanos', 'Call us')}
      </Button>
    </Alert>,

    <Alert
      key="contactBanner1"
      id="contactBanner1"
      status="info"
      variant="solid"
      maxW="55em"
      m="2em auto"
      height={['100px', 'auto']}
      lineHeight={1.2}
    >

      <AlertIcon height="20px" width="20px" boxSize="20px" display={['none', 'inline-block']} />
      <Box as="span" flexGrow="1" display={['none', 'inline-block']}>
        {l('Habla con nuestros expertos y aclara todas tus dudas', 'Talk to our advisors and answer all your questions')}
      </Box>
      <Box as="span" display={['inline-block', 'none']}>
        {l('Habla con expertos y aclara tus dudas', 'Talk to experts and answer all your questions')}
      </Box>
      <Button
        as="a"
        href="/contacto"
        onClick={(e) => {
          e.preventDefault();
          openContacto(1);
        }}
        size="sm"
        variant="outline"
        colorScheme="white"
        minWidth="166px"
        marginLeft={['14px', '32px']}
        backgroundColor="#fff"
        color="#3182ce"
        _hover={{ bg: '#1976d266', color: '#fff' }}
        paddingLeft={['4px', '0']}
        flexGrow="1"
      >
        <img
          src="/avatars/call-female.png"
          alt="avatar calling on the phone"
          loading="lazy"
          height="64" width="64"
          style={{ height: '64px' }}
        />
        {l('¡Contáctanos!', 'Contact us!')}
      </Button>
    </Alert>,

    <Alert
      key="contactBanner2"
      id="contactBanner2"
      status="info"
      variant="solid"
      maxW="55em"
      m="2em auto"
      lineHeight={1.2}
      height={['90px', 'auto']}
    >
      <AlertIcon height="20px" width="20px" boxSize="20px" display={['none', 'inline-block']} />
      <img
        src="/avatars/call-female.png"
        alt="avatar calling on the phone"
        loading="lazy"
        height="61" width="61"
        style={{ height: '61px', marginRight: '7px', marginLeft: '-8px' }}
      />
      <Box as="span" flexGrow="1" display={['inline-block', 'inline-block']}>
        {l('¡Nuestros expertos están listos para ayudarte!',
        'Our experts are ready to help you!')}
      </Box>
      <Button
        as="a"
        href="/contacto"
        onClick={(e) => {
          e.preventDefault();
          openContacto(2);
        }}
        size="sm"
        variant="outline"
        colorScheme="white"
        minWidth="118px"
        marginLeft={['2px', '32px']}
        leftIcon={<PhoneIcon ml=""/>}
        backgroundColor="#fff"
        color="#3182ce"
        _hover={{ bg: '#1976d266', color: '#fff' }}
      >
        {l('Llamanos', 'Call us')}
      </Button>
    </Alert>,

    <Alert
      key="contactBanner3"
      id="contactBanner3"
      status="info"
      variant="top-accent"
      maxW="55em"
      m="2em auto"
      lineHeight={1.2}
      height={['110px', 'auto']}
    >
      <AlertIcon height="20px" width="20px" boxSize="20px" display={['none', 'inline-block']} />
      
      <img
        src="/avatars/avatar-asesora.svg"
        alt="avatar calling on the phone"
        loading="lazy"
        height="48" width="48"
        style={{ height: '48px', marginRight: '12px', marginLeft: '-2px' }}
      />
      <Box as="span" flexGrow="1" display={['none', 'inline-block']}>
        {l('Habla con nuestros expertos en el tema y cotiza tu proyecto',
          'Talk to our experts and get a quote')}
      </Box>
      <Box as="span" display={['inline-block', 'none']}>
        {l('Habla con nuestros expertos y cotiza tu proyecto',
          'Talk to our experts and get a quote for your project')}
      </Box>

      <Button
        as="a"
        href="/contacto"
        onClick={(e) => {
          e.preventDefault();
          openContacto(3);
        }}
        leftIcon={<PhoneIcon ml=""/>}
        size="sm"
        variant="solid"
        colorScheme="azulin"
        minWidth="120px"
        marginLeft={['6px', '32px']}
        style={{color: 'white'}}
      >
        {l('¡Hablemos!', 'Let\'s talk!')}
      </Button>
    </Alert>,

    <Alert
      key="contactBanner4"
      id="contactBanner4"
      status="info"
      variant="left-accent"
      maxW="55em"
      m="2em auto"
      lineHeight={1.2}
      height={['100px', 'auto']}
    >
      <AlertIcon height="20px" width="20px" boxSize="20px" display={['none', 'inline-block']} />
      <img
        src="/avatars/avatar-asesorJoven.svg"
        alt="avatar calling on the phone"
        loading="lazy"
        height="48" width="48"
        style={{ height: '48px', marginRight: '16px' }}
      />
      <Box as="span" flexGrow="1" display={['none', 'inline-block']}>
        {l('No pierdas mas tiempo, cotiza tu proyecto con nuestros asesores',
          'Dont waste more time, get a quote for your project with our advisors.')}
      </Box>
      <Box as="span" display={['inline-block', 'none']}>
        {l('Cotiza tu proyecto con un experto',
          'Quote your project with an expert')}
      </Box>

      <Button
        as="a"
        href="/contacto"
        onClick={(e) => {
          e.preventDefault();
          openContacto(4);
        }}
        leftIcon={<PhoneIcon ml=""/>}
        size="sm"
        variant="outline"
        colorScheme="white"
        minWidth="120px"
        marginLeft={['2px', '32px']}
        backgroundColor="#fff"
        color="#3182ce"
        _hover={{ bg: '#1976d266', color: '#fff' }}
      >
        {l('¡Hablemos!', 'Let\'s talk!')}
      </Button>
    </Alert>,

    <Alert
      key="contactBanner5"
      id="contactBanner5"
      status="info"
      // variant="ghost"
      p={['16px', '2em']}
      maxW="70em"
      m="4em auto"
      // width={['auto', '100%']}
      height={['100px', 'auto']}
      lineHeight={1.2}
      background={'#ceedff33'}
      border="1px solid #ceedff66"
    >
      <AlertIcon height="20px" width="20px" boxSize="20px" display={['none', 'inline-block']} />
      <Box as="span" flexGrow="1" display={['none', 'inline-block']}>
        {l('Habla con nuestros expertos y aclara todas tus dudas', 'Experts will answer all your questions')}
      </Box>
      <Box as="span" display={['inline-block', 'none']}>
        {l('Habla con expertos y aclara tus dudas', 'Our experts will answer all your questions')}
      </Box>
      <Button
        as="a"
        href="/contacto"
        onClick={(e) => {
          e.preventDefault();
          openContacto(5);
        }}
        size="sm"
        variant="outline"
        colorScheme="white"
        minWidth="166px"
        marginLeft={['14px', '32px']}
        backgroundColor="#fff"
        color="#3182ce"
        _hover={{ bg: '#1976d266', color: '#fff' }}
        paddingLeft={['4px', '0']}
        flexGrow="1"
      >
        <img
          src="/avatars/call-female.png"
          alt="avatar calling on the phone"
          loading="lazy"
          height="64" width="64"
          style={{ height: '64px' }}
        />
        {l('¡Contáctanos!', 'Contact us!')}
      </Button>
    </Alert>,
  ];

  // eslint-disable-next-line no-unused-vars
  const [index, setIndex] = useState(
    type >= 0 && type <= items.length
      ? type
      : Math.floor(Math.random() * items.length)
  );

  return items[index];
};

export default ContactBanner;

// const ContactBanerForm =
{/* 
  <>
   <Alert status="error" variant="left-accent" maxW="50em" m="2em auto">
        <AlertIcon />
        No pierdas mas tiempo, cotiza tu proyecto con nuestros asesores.
        <Button
          as="a"
          href="/contacto"
          onClick={(e) => {
            e.preventDefault();
            toggleDrawer();
          }}
          leftIcon={<PhoneIcon ml=""/>}
          size="sm"
          variant="solid"
          colorScheme="gray"
          minWidth="120px"
          marginLeft="32px"
          backgroundColor="#ffffff"
          color="#f00"
          _hover={{ bg: '#ffffffdd', color: '#ff0000' }}
        >
          ¡Hablemos!
        </Button>
      </Alert> 

      <Alert status="info" variant="left-accent" maxW="40em" m="2em auto">
        <AlertIcon />
        <Stack isInline spacing={4}>
          <InputGroup>
            <InputLeftElement>
              <InfoOutlineIcon color="azulin.300" />
            </InputLeftElement>
            <Input type="text" placeholder="Nómbre" />
          </InputGroup>

          <InputGroup>
            <InputLeftElement>
              <PhoneIcon ml="" color="azulin.300" />
            </InputLeftElement>
            <Input type="phone" placeholder="Teléfono" />
          </InputGroup>

        </Stack>
        <Button
          leftIcon={<PhoneIcon ml=""/>}
          size="sm"
          variant="solid"
          colorScheme="azulin"
          minWidth="160px"
          marginLeft="32px"
        >
          Agendar llamada
    </Button>
      </Alert >

      <Alert status="info" variant="left-accent" maxW="60em" m="2em auto">
        <span
          role="img"
          aria-label="Alarm clock"
          style={{ fontSize: '1.3em', margin: '0 12px 0 0px' }}
        >
          📞
        </span>
        Habla con nuestros asesores.

        <Stack isInline spacing={4} ml="32px">
          <InputGroup>
            <Input type="text" placeholder="Nómbre" />
          </InputGroup>

          <InputGroup>
            <InputLeftElement>
              <PhoneIcon ml="" color="azulin.300" />
            </InputLeftElement>
            <Input type="phone" placeholder="Teléfono" />
          </InputGroup>
        </Stack>
        <Button
          leftIcon={<PhoneIcon ml=""/>}
          size="sm"
          variant="solid"
          colorScheme="azulin"
          minWidth="160px"
          marginLeft="32px"
        >
          Agendar llamada
        </Button>
      </Alert>
      <Alert status="info" variant="left-accent" maxW="40em" m="2em auto">
        <AlertIcon />
        <Stack isInline spacing={4}>
          <InputGroup>
            <InputLeftElement>
              <InfoOutlineIcon color="azulin.300" />
            </InputLeftElement>
            <Input type="text" placeholder="Nómbre" />
          </InputGroup>
          
          <InputGroup>
            <InputLeftElement>
              <PhoneIcon ml="" color="azulin.300" />
            </InputLeftElement>
            <Input type="phone" placeholder="Teléfono" />
          </InputGroup>

        </Stack>
        <Button
          leftIcon={<PhoneIcon ml=""/>}
          size="sm"
          variant="solid"
          colorScheme="azulin"
          minWidth="160px"
          marginLeft="32px"
        >
      Agendar llamada
    </Button>
  </Alert >

  <Alert status="info" variant="left-accent" maxW="60em" m="2em auto">
  <span
    role="img"
    aria-label="Alarm clock"
    style={{ fontSize: '1.3em', margin: '0 12px 0 0px' }}
  >
    📞
        </span>
        Habla con nuestros asesores.

        <Stack isInline spacing={4} ml="32px">
    <InputGroup>
      <Input type="text" placeholder="Nómbre" />
    </InputGroup>

    <InputGroup>
      <InputLeftElement>
        <PhoneIcon ml="" color="azulin.300" />
      </InputLeftElement>
      <Input type="phone" placeholder="Teléfono" />
    </InputGroup>
  </Stack>
  <Button
    leftIcon={<PhoneIcon ml=""/>}
    size="sm"
    variant="solid"
    colorScheme="azulin"
    minWidth="160px"
    marginLeft="32px"
  >
    Agendar llamada
        </Button>
</Alert> 


<Alert
        status="info"
        variant="subtle"
        flexDirection="column"
        justifyContent="center"
        textAlign="center"
        height="auto"
        maxW="40em"
        m="2em auto"
        p="2em"
      >
        <AlertIcon size="40px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          ¡Habla con un experto!
        </AlertTitle>
        <AlertDescription maxWidth="sm">
          Agenda una llamada con nuestros asesores para saber como podemos ayudarte.
        </AlertDescription>
        <Stack isInline spacing={4} m="1.2em auto 0em" maxW="28em">
          <InputGroup>
  < Input type = "text" placeholder = "Nómbre" />
          </InputGroup >

          <InputGroup>
            <InputLeftElement>
              <PhoneIcon ml="" color="azulin.300" />
            </InputLeftElement>
            <Input type="phone" placeholder="Teléfono" />
          </InputGroup>

          <InputGroup>
            <Button
              size="sm"
              variant="solid"
              colorScheme="azulin"
              minWidth="160px"
            >
      Agendar llamada
            </Button>
            </InputGroup>
        </Stack >
      </Alert >
*/}