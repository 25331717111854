import { extendTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';


const theme = extendTheme({
  styles: {
    global: (props) => ({
      body: {
        bg: mode('#fcfcfc', '#272727')(props),
        color: mode('#485963', '#d0d0d0')(props),
      },
    }),
  },
  fonts: {
    heading: `"Inter", "Helvetica Neue","Segoe UI","Helvetica","Nimbus Sans L","Liberation Sans","Open Sans",FreeSans,sans-serif;`,
    body: `"Inter", "Helvetica Neue","Segoe UI","Helvetica","Nimbus Sans L","Liberation Sans","Open Sans",FreeSans,sans-serif;`,
  },
  colors: {
    brand: {
      900: '#1a365d',
      800: '#153e75',
      700: '#2a69ac',
    },

    azulContrast: {
      50: '#afddff',
      100: '#80caff',
      200: '#52bbfe',
      300: '#2eb1fd',
      400: '#1f9ee4',
      500: '#0f80b2',
      600: '#006080',
      700: '#00344f',
      800: '#00101f',
      900: '#00101f',
    },
    azulin: {
      50: '#dbf3ff',
      100: '#afddff',
      200: '#80caff',
      300: '#52bbfe',
      400: '#2eb1fd',
      500: 'hsl(202 91% 38% / 1)',
      // 500: '#1f9ee4',
      // 500: 'hsl(198 94% 36% / 1)',
      600: '#0f80b2',
      700: '#006080',
      800: '#00344f',
      900: '#00101f',
    }, //hsl(198 94% 36% / 1)

    azulin1: {
      50: '#def6ff',
      100: '#b8dff8',
      200: '#8fc8ee',
      300: '#65b2e5',
      400: '#3c9cdc',
      500: '#257cb9',
      600: '#166699',
      700: '#09496e',
      800: '#002c45',
      900: '#00101d',
    },
  },
});

export default theme