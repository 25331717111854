import ReactCompareImage from 'react-compare-image';
import { Alert, AlertIcon, AlertTitle, AlertDescription, Button } from "@chakra-ui/react";
import megaGreen from "../../../../proyectos/set-virtual-streaming-convencion-online/megaVirtualGreen.jpg";
import megaEscenario from "../../../../proyectos/set-virtual-streaming-convencion-online/megaVirtualc.jpg";
import motuGreen from "../../../../proyectos/set-virtual-streaming-convencion-online/motuGreen3.jpg";
import motuEscenario from "../../../../proyectos/set-virtual-streaming-convencion-online/motu3.jpg";
import ppGreen from "../../../../proyectos/set-virtual-streaming-convencion-online/powerPointVirtualGreenScreenc.jpg";
import ppEscenario from "../../../../proyectos/set-virtual-streaming-convencion-online/powerPointVirtualc.jpg";
import presGreen from "../../../../proyectos/set-virtual-streaming-convencion-online/presentacionVirtualGreenc.jpg";
import presEscenario from "../../../../proyectos/set-virtual-streaming-convencion-online/presentacionVirtualc.jpg";
import hwGreen from "../../../../proyectos/set-virtual-streaming-convencion-online/presentacion-automoviles-virtual-hotwheelsGreenc.jpg";
import hwEscenario from "../../../../proyectos/set-virtual-streaming-convencion-online/presentacion-automoviles-virtual-hotwheelsc.jpg";
import sinEscenario from "../../../../proyectos/set-virtual-streaming-convencion-online/green-screen-fisher-price.jpg";
import conEscenario from "../../../../proyectos/set-virtual-streaming-convencion-online/escenario-virtual-fisher-price.jpg";
import * as React from 'react';
export default {
  ReactCompareImage,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Button,
  megaGreen,
  megaEscenario,
  motuGreen,
  motuEscenario,
  ppGreen,
  ppEscenario,
  presGreen,
  presEscenario,
  hwGreen,
  hwEscenario,
  sinEscenario,
  conEscenario,
  React
};