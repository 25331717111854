import React, { useState } from 'react';
import PropTypes from 'prop-types';

const myContext = React.createContext();
const defaultLang = 'es'
const Provider = (props) => {
  const [isDark, setTheme] = useState(false);
  const [isDrawerOpen, toggleDrawer] = useState(false);
  const [lang, setLang] = useState(defaultLang);

  return (
    <myContext.Provider
      value={{
        isDark,
        changeTheme: () => setTheme(!isDark),
        isDrawerOpen,
        toggleDrawer: () => toggleDrawer(!isDrawerOpen),
        openDrawer: () => toggleDrawer(true),
        closeDrawer: () => toggleDrawer(false),

        lang,
        changeLang: (lang) => setLang(lang),
      }}
    >
      {props.children}
    </myContext.Provider>
  );
};
Provider.propTypes = {
  children: PropTypes.node.isRequired,
};

const wrapWithProvider = ({ element }) => <Provider>{element}</Provider>;
wrapWithProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { wrapWithProvider, Provider, myContext };
