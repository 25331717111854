// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-animacion-modelado-3-d-js": () => import("./../../../src/pages/animacion-modelado-3d.js" /* webpackChunkName: "component---src-pages-animacion-modelado-3-d-js" */),
  "component---src-pages-aplicaciones-videojuegos-js": () => import("./../../../src/pages/aplicaciones-videojuegos.js" /* webpackChunkName: "component---src-pages-aplicaciones-videojuegos-js" */),
  "component---src-pages-arquitectura-recorrido-virtual-js": () => import("./../../../src/pages/arquitectura-recorrido-virtual.js" /* webpackChunkName: "component---src-pages-arquitectura-recorrido-virtual-js" */),
  "component---src-pages-capacitacion-realidad-virtual-js": () => import("./../../../src/pages/capacitacion-realidad-virtual.js" /* webpackChunkName: "component---src-pages-capacitacion-realidad-virtual-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-desarrollo-web-apps-js": () => import("./../../../src/pages/desarrollo-web-apps.js" /* webpackChunkName: "component---src-pages-desarrollo-web-apps-js" */),
  "component---src-pages-en-3-d-animation-js": () => import("./../../../src/pages/en/3d-animation.js" /* webpackChunkName: "component---src-pages-en-3-d-animation-js" */),
  "component---src-pages-en-3-d-mapping-projection-js": () => import("./../../../src/pages/en/3d-mapping-projection.js" /* webpackChunkName: "component---src-pages-en-3-d-mapping-projection-js" */),
  "component---src-pages-en-apps-videogames-js": () => import("./../../../src/pages/en/apps-videogames.js" /* webpackChunkName: "component---src-pages-en-apps-videogames-js" */),
  "component---src-pages-en-architecture-virtual-tours-js": () => import("./../../../src/pages/en/architecture-virtual-tours.js" /* webpackChunkName: "component---src-pages-en-architecture-virtual-tours-js" */),
  "component---src-pages-en-augmented-reality-js": () => import("./../../../src/pages/en/augmented-reality.js" /* webpackChunkName: "component---src-pages-en-augmented-reality-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-interactive-museums-js": () => import("./../../../src/pages/en/interactive-museums.js" /* webpackChunkName: "component---src-pages-en-interactive-museums-js" */),
  "component---src-pages-en-projects-js": () => import("./../../../src/pages/en/projects.js" /* webpackChunkName: "component---src-pages-en-projects-js" */),
  "component---src-pages-en-virtual-production-film-js": () => import("./../../../src/pages/en/virtual-production-film.js" /* webpackChunkName: "component---src-pages-en-virtual-production-film-js" */),
  "component---src-pages-en-virtual-reality-js": () => import("./../../../src/pages/en/virtual-reality.js" /* webpackChunkName: "component---src-pages-en-virtual-reality-js" */),
  "component---src-pages-en-virtual-reality-training-js": () => import("./../../../src/pages/en/virtual-reality-training.js" /* webpackChunkName: "component---src-pages-en-virtual-reality-training-js" */),
  "component---src-pages-equipo-js": () => import("./../../../src/pages/equipo.js" /* webpackChunkName: "component---src-pages-equipo-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-museos-instalaciones-js": () => import("./../../../src/pages/museos-instalaciones.js" /* webpackChunkName: "component---src-pages-museos-instalaciones-js" */),
  "component---src-pages-proyectos-js": () => import("./../../../src/pages/proyectos.js" /* webpackChunkName: "component---src-pages-proyectos-js" */),
  "component---src-pages-realidad-aumentada-js": () => import("./../../../src/pages/realidad-aumentada.js" /* webpackChunkName: "component---src-pages-realidad-aumentada-js" */),
  "component---src-pages-realidad-virtual-js": () => import("./../../../src/pages/realidad-virtual.js" /* webpackChunkName: "component---src-pages-realidad-virtual-js" */),
  "component---src-pages-set-virtual-produccion-cine-tv-js": () => import("./../../../src/pages/set-virtual-produccion-cine-tv.js" /* webpackChunkName: "component---src-pages-set-virtual-produccion-cine-tv-js" */),
  "component---src-pages-video-mapping-js": () => import("./../../../src/pages/video-mapping.js" /* webpackChunkName: "component---src-pages-video-mapping-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-templates-proyecto-js": () => import("./../../../src/templates/proyecto.js" /* webpackChunkName: "component---src-templates-proyecto-js" */)
}

